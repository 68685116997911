<template>
  <div class="card-sections-block-header-tmk">

    <el-row type="flex" justify="space-between" style="margin-bottom: 20px">
      <div style="font-size: 24px">
        Карта КПЭ
      </div>

      <div>
        <el-button
          v-if="$canAndRulesPass(user, 'card.send_changes_notify')"
          style="margin-left:35px"
          type="text"
          size="mini"
          icon="far fa-bell"
          :loading="loadingNotify"
          @click="sendNotify"
        >
          Оповестить об изменениях
        </el-button>
        <history-modal
          v-if="$canAndRulesPass(card, 'card.see_history')"
          style="display: inline-block; margin-left:35px"
          title="История изменений карты"
          width="70%"
          show-model-full-name
          :method="$api.card.getCardHistory"
          :params="{  id: card.id }"
        >
          <el-button
            slot="reference"
            type="text"
            size="mini"
            icon="fas fa-history"
          >
            История изменений
          </el-button>
        </history-modal>

        <el-button
          style="margin-left:35px"
          type="text"
          size="mini"
          icon="fas fa-file-excel"
          :loading="loadingDownloader"
          @click="exportToExcel"
        >
          Экспорт в XLSX
        </el-button>

        <el-dropdown @command="exportToWord">
          <el-button
            style="margin-left:35px"
            type="text"
            size="mini"
            icon="fas fa-file-word"
            :loading="loadingDownloader"
          >
            Выгрузить ДС <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="dc">ДС</el-dropdown-item>
            <el-dropdown-item command="long_dc">ДС долгосрочное</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>


      </div>
    </el-row>

    <el-table
      class="card-summary-table"
      header-row-class-name="card-section-elements-table-header"
      style="width: 100%; margin-bottom: 25px"
      size="mini"
      :data="summary"
      border
    >
      <el-table-column label="ИТОГО"></el-table-column>
      <el-table-column label="Вес КПЭ (план)" :width="width.weight">
        <template v-slot="scope" >
          {{ scope.row.weight|numberFormat }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.card.status===this.$constants.card.statuses.STATUS_COMPLETED || (currentStage && currentStage.type !== $constants.stage.types.TYPE_PLAN)"
        label="Вес КПЭ (факт)"
        :width="width.weightFact"
      >
        <template v-slot="scope" >
          {{ scope.row.weightFact|numberFormat }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.card.status===this.$constants.card.statuses.STATUS_COMPLETED || (currentStage && currentStage.type === $constants.stage.types.TYPE_ASSESSMENT)"
        label="% скорр."
        :width="width.correction"
      >
        <template v-slot="scope" >
          {{ scope.row.correction|numberFormat }}
        </template>
      </el-table-column>
      <el-table-column
        v-if="this.card.status===this.$constants.card.statuses.STATUS_COMPLETED || (currentStage && currentStage.type !== $constants.stage.types.TYPE_PLAN)"
        label="Итог"
        :width="width.fact"
      >
        <template v-slot="scope" >
          {{ scope.row.bonus|numberFormat }}
        </template>
      </el-table-column>
    </el-table>



  </div>
</template>

<script>

import {mapGetters} from "vuex";
import HistoryModal from "@/components/HistoryModal.vue";
import {downloader} from "@/mixins/downloader";
import requestSender from "@/api/base/requestSender";

export default {
  name: "card-sections-block-header",
  mixins: [downloader],
  components: {HistoryModal},
  props: {},

  computed: {
    ...mapGetters(['user']),
    ...mapGetters('card', {
      card:'card'
    }),
    ...mapGetters('card/stages', {
      currentStage: 'currentStage',
    }),
    ...mapGetters('card/sections', {
      sectionsElements: 'sectionsElements'
    }),
    summary(){
      let totalWeight = 0;
      let totalWeightFact = 0;
      let totalCorrection = 0;
      let totalBonus = 0;

      for (const [sectionId, elements] of Object.entries(this.sectionsElements)) {
        elements.forEach(e => {
          totalWeight += +e.scale.weight_plan;
          totalWeightFact += +e.scale.weight_fact;
          totalCorrection += +e.scale.correction;
          totalBonus += +e.bonus;
        })
      }

      return [{
        weight: +totalWeight.toFixed(2),
        weightFact: +totalWeightFact.toFixed(6),
        correction: +totalCorrection.toFixed(2),
        bonus: +totalBonus.toFixed(2),
      }]
    },

    width(){
      let weightWidth = 0;
      let weightFactWidth = 0;
      let correctionWidth = 0;
      let factWidth = 0;
      if( this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_PLAN ){
        weightWidth = 380;
      }
      if( this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_INTERMEDIATE_ASSESSMENT ){
        weightWidth = 720;
        weightFactWidth = 160;
        factWidth = 130;
      }
      if( this.card.status===this.$constants.card.statuses.STATUS_COMPLETED || (this.currentStage && this.currentStage.type === this.$constants.stage.types.TYPE_ASSESSMENT) ){
        weightWidth = 720;
        weightFactWidth = 80;
        correctionWidth = 80;
        factWidth = 250;
      }
      return{
        'weight': weightWidth,
        'weightFact': weightFactWidth,
        'correction': correctionWidth,
        'fact': factWidth,
      }
    }
  },
  watch: {},
  data() {
    return {
      loadingNotify: false,
    }
  },
  methods: {
    exportToExcel() {
      this.downloadFile(
        false,
        this.$api.card.exportToXls,
        {id: this.card.id}
      )
    },

    exportToWord(type) {
      const url = type==='dc' ? 'export/export-to-word' : 'export/long-export-to-word';

      this.loadingDownloader = true;
      requestSender('post', url,
        {id: this.card.id,},
        {responseType: 'blob'}
      )
        .then(({data, headers}) => {
          this.parseDownloadedResult(false, data, headers);
        })
        .finally(() => {
          this.loadingDownloader = false;
        })
    },

    sendNotify(){
      this.loadingNotify = true;
      requestSender('post', 'notify/card-changed', {
        id: this.card.id,
      })
        .then(data => {
          this.$notify.success({
            title: 'Отправлено',
            message: 'Уведомление успешно отправлено'
          });
        })
        .finally(() => {
          this.loadingNotify = false;
        })
    },
  }
}
</script>


<style lang="scss" scoped>
@import "./src/assets/css/project-variables";

.card-summary-table {
  color: $--color-primary;
  font-weight: bold;
}
</style>